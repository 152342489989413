.form-table {
  .MuiListItem-root {
    box-shadow: none;
    padding: 8px;
    margin-bottom: 0px;
    .MuiListItem-divider {
      border-bottom: 1px solid #dbdbdb;
    }
    .right-space {
      width: 100px;
      text-align: right;
    }
    span {
      .MuiFormControl-root {
        margin-bottom: 0px;
      }
    }
    .MuiInputBase-input {
      padding: 4px 8px;
      text-align: right;
      font-size: 12px;
      border: 1.5px solid #0019d8;
      border-radius: 3px;
    }

    .MuiInputBase-root {
      width: 100px;
      margin: 0px 0px 0px auto;
    }
  }
  .bg-grey {
    background: #efefef;
  }
  .bg-pink {
    background: #ffedf7;
  }
}

.mgmt-search-header {
  padding: 8px;
  background-color: #bfd3fe;
  .MuiSelect-outlined.MuiSelect-outlined {
    padding: 8px;
    background-color: #fff;
    font-size: 13px;
    font-weight: 500;
  }
  .header {
    padding: 10px 0px;
    background-color: #fff;
  }
}
